body {
  background: url('./images/background.png');
  margin: 0;
  padding : 0;
}
  
.App {
  margin: 10px auto;
  padding : 5px;
}

.MuiAppBar-root {
  color : #fff !important;
}

.recharts-legend-wrapper {
  top: 5px !important;
}

.App div.stageMenu {
  float: right;
}
.App div.stageMenu a {
  margin-right: 10px;
}
.App div.stageMenu a.active {
  color : #fff;
}

h6 {
  font-size : 24px !important;
  color : #fff;
}

button.active, a.active {
  color : #fff;
}

.ag-theme-alpine .ag-header-cell.rightAlignHeader {
  padding-left : 0;
  padding-right : 20;
  text-align: right;
}

.ag-theme-alpine .ag-header-cell.rightAlignHeader .ag-header-cell-label {
  flex: none !important;
}

.numberGridCell {
  text-align: right !important;
}

.numberGridCellBold {
  text-align: right !important;
  font-weight : bold !important;
}

.textGridCell {
  text-align: left !important;
}

.tableHeader {
  opacity: 0.5;
}v

.tableHeaderNumber {
  text-align: right !important;
  opacity: 0.5;
}

#pdfiframe {
  display : none;
}

.uploaderDropZone {
  width : 400px !important;
  min-height : 100px !important;
/*  margin-top : -100px; */
}

.uploaderDropZoneLarge {
  width : 100% !important;
  min-height : 200px !important;
}

.voidedInvoice {
  background-color: lightcoral !important;
}

pre {
  font-size: inherit;
  color: inherit;
  border: initial;
  padding: initial;
  font-family: inherit;
  white-space: pre-wrap; 
}

.selectGroupHeader {
  color : black !important;
  background-color : grey !important;
  padding-top : 15px;
  font-size : 18px !important;
}

.indentedSelectOption {
  padding-left : 30px !important;
}

.wrongYear {
  color : red;
}

.vatLegalText {
  font-size: 15px;
  width : 600px;
  text-align: left;
  margin-left: 10px;
  color: red;
}